import React from "react";
import { FormattedMessage } from "react-intl";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { Button } from "../input/Button";
import { useCssBreakpoints } from "react-use-css-breakpoints";

export function EnterRandomRoomButton() {
    const breakpoint = useCssBreakpoints();

    return (
        <Button
            thick={breakpoint === "sm" || breakpoint === "md"}
            xl={breakpoint !== "sm" && breakpoint !== "md"}
            preset="landing"
            onClick={e => {
                e.preventDefault();
                location.assign('./api/v1/hubs-redirect?event=lobby');
            }}
        >
            <FormattedMessage id="enter-random-room-button" defaultMessage="Enter Event" />
        </Button>
    );
}
